import { jsx as _jsx } from "react/jsx-runtime";
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { Error } from './error';
let errorRoot;
export const bootstrap = async (props) => {
    const { domElement } = props;
    errorRoot = createRoot(domElement);
};
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const mount = async (props) => {
    const onBack = async () => {
        window.location.replace(window.location.pathname);
    };
    errorRoot.render(_jsx(StrictMode, { children: _jsx(Error, { onBack: onBack }) }));
};
export const unmount = async () => {
    errorRoot.unmount();
};
