// eslint-disable-next-line simple-import-sort/imports
import './config/sentry';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as ReactHookForm from 'react-hook-form';
import * as ReactI18next from 'react-i18next';
import * as ReactRouterDOM from 'react-router-dom';
import * as ReactTransitionGroup from 'react-transition-group';
import * as BifrostKeycloakClient from '@bifrost/keycloak-client';
import * as BifrostWebComponents from '@bifrost/web-components';
import * as BifrostWebTemplates from '@bifrost/web-templates';
import * as Connect from '@connectrpc/connect';
import * as ConnectQuery from '@connectrpc/connect-query';
import * as ConnectWeb from '@connectrpc/connect-web';
import * as DNDCore from '@dnd-kit/core';
import * as DNDModifiers from '@dnd-kit/modifiers';
import * as DNDSortable from '@dnd-kit/sortable';
import * as HookformResolvers from '@hookform/resolvers';
import * as ReactQuery from '@tanstack/react-query';
import * as DayJS from 'dayjs';
import * as I18next from 'i18next';
import * as Lodash from 'lodash';
import { registerApplication, start, addErrorHandler, mountRootParcel } from 'single-spa';
import * as Zod from 'zod';
import * as CrowdinOTAClient from '@crowdin/ota-client';
import * as errorParcel from './components/error';
import { createModuleDefinition } from '@utils/create_module_definition';
import 'systemjs';
import 'import-map-overrides';
import { queryClient } from './config/query_client';
import './index.styles.scss';
window.bifrostWeb = {
    ...window.bifrostWeb,
    capabilities: ['scanDeviceQRCode', 'connectToDevice', 'getWiFiNetworks', 'configureDevice', 'finishOnboarding'],
    navigate: path => window.history.pushState({}, '', path),
    getIsMobile: () => !!window.bifrostNative,
};
const landingOnboarding = localStorage.getItem('landing-onboarding');
const manualInit = window.bifrostWeb.getIsMobile() && !landingOnboarding;
const { keycloak, initKeycloak } = BifrostKeycloakClient.createClient({
    url: window.bifrostWeb.keycloak.url,
    realm: window.bifrostWeb.keycloak.realm,
    clientId: window.bifrostWeb.keycloak.clientId,
    manualInit,
});
if (manualInit && !keycloak.didInitialize) {
    window.history.replaceState({}, '', '/landing/onboarding');
}
System.set('dependencies:react', React);
System.set('dependencies:react-dom', ReactDOM);
System.set('dependencies:react-router-dom', ReactRouterDOM);
System.set('dependencies:react-hook-form', ReactHookForm);
System.set('dependencies:react-transition-group', ReactTransitionGroup);
System.set('dependencies:dayjs', DayJS);
System.set('dependencies:@dnd-kit/core', DNDCore);
System.set('dependencies:@dnd-kit/modifiers', DNDModifiers);
System.set('dependencies:@dnd-kit/sortable', DNDSortable);
System.set('dependencies:lodash', Lodash);
System.set('dependencies:@connectrpc/connect', Connect);
System.set('dependencies:@connectrpc/connect-query', ConnectQuery);
System.set('dependencies:@connectrpc/connect-web', ConnectWeb);
System.set('dependencies:@tanstack/react-query', ReactQuery);
System.set('dependencies:@bifrost/web-components', BifrostWebComponents);
System.set('dependencies:@bifrost/web-templates', BifrostWebTemplates);
System.set('dependencies:@bifrost/keycloak-client', BifrostKeycloakClient);
System.set('dependencies:i18next', I18next);
System.set('dependencies:react-i18next', ReactI18next);
System.set('dependencies:zod', Zod);
System.set('dependencies:@hookform/resolvers', HookformResolvers);
System.set('dependencies:@crowdin/ota-client', CrowdinOTAClient);
addErrorHandler(appError => {
    const domElement = document.getElementById('error');
    mountRootParcel(errorParcel, { domElement, appError });
});
window.bifrostAuth.subscribe(Symbol(), ({ authError }) => {
    if (authError) {
        const domElement = document.getElementById('error');
        mountRootParcel(errorParcel, { domElement, authError });
    }
});
const commonModuleProps = {
    landscape: window.bifrostWeb.landscape,
    backend: window.bifrostWeb.backend,
    queryClient,
};
const sharedModuleDefinition = createModuleDefinition('shared');
registerApplication({
    name: sharedModuleDefinition.name,
    app: () => System.import(sharedModuleDefinition.name),
    activeWhen: () => true,
    customProps: {
        ...commonModuleProps,
        modulePath: sharedModuleDefinition.url,
        appId: 'bif',
    },
});
const playModuleDefinition = createModuleDefinition('play');
registerApplication({
    name: playModuleDefinition.name,
    app: () => System.import(playModuleDefinition.name),
    activeWhen: playModuleDefinition.activePath,
    customProps: {
        ...commonModuleProps,
        modulePath: playModuleDefinition.url,
    },
});
const devicesModuleDefinition = createModuleDefinition('devices');
registerApplication({
    name: devicesModuleDefinition.name,
    app: () => System.import(devicesModuleDefinition.name),
    activeWhen: devicesModuleDefinition.activePath,
    customProps: {
        ...commonModuleProps,
        modulePath: devicesModuleDefinition.url,
    },
});
const servicesModuleDefinition = createModuleDefinition('services');
registerApplication({
    name: servicesModuleDefinition.name,
    app: () => System.import(servicesModuleDefinition.name),
    activeWhen: servicesModuleDefinition.activePath,
    customProps: {
        ...commonModuleProps,
        modulePath: servicesModuleDefinition.url,
    },
});
const profileModuleDefinition = createModuleDefinition('profile');
registerApplication({
    name: profileModuleDefinition.name,
    app: () => System.import(profileModuleDefinition.name),
    activeWhen: profileModuleDefinition.activePath,
    customProps: {
        ...commonModuleProps,
        modulePath: profileModuleDefinition.url,
    },
});
const homeModuleDefinition = createModuleDefinition('home');
registerApplication({
    name: homeModuleDefinition.name,
    app: () => System.import(homeModuleDefinition.name),
    activeWhen: homeModuleDefinition.activePath,
    customProps: {
        ...commonModuleProps,
        modulePath: homeModuleDefinition.url,
    },
});
const landingModuleDefinition = createModuleDefinition('landing');
registerApplication({
    name: landingModuleDefinition.name,
    app: () => System.import(landingModuleDefinition.name),
    activeWhen: landingModuleDefinition.activePath,
    customProps: {
        ...commonModuleProps,
        initKeycloak,
        modulePath: landingModuleDefinition.url,
    },
});
const navigationModuleDefinition = createModuleDefinition('navigation');
registerApplication({
    name: navigationModuleDefinition.name,
    app: () => System.import(navigationModuleDefinition.name),
    activeWhen: () => true,
    customProps: {
        ...commonModuleProps,
        modulePath: navigationModuleDefinition.url,
    },
});
start();
