import { browserTracingIntegration, init, replayIntegration, sessionTimingIntegration } from '@sentry/browser';
const allowedLandscapes = ['dev', 'int'];
const possibleOverrides = ['shared', 'landing', 'home', 'services', 'play', 'devices', 'profile'];
if (allowedLandscapes.includes(window.bifrostWeb.landscape)) {
    init({
        dsn: window.bifrostWeb.sentry.url,
        environment: window.bifrostWeb.landscape,
        integrations: [
            browserTracingIntegration(),
            replayIntegration({
                maskAllText: true,
                blockAllMedia: false,
            }),
            sessionTimingIntegration(),
        ],
        beforeSend(event) {
            const existingOverrides = possibleOverrides
                .map(name => localStorage.getItem(`import-map-override:@bifrost/${name}`))
                .filter(Boolean);
            const disabledOverrides = JSON.parse(localStorage.getItem('import-map-overrides-disabled') || '[]');
            if (existingOverrides.length > disabledOverrides.length) {
                return null;
            }
            return event;
        },
        tracesSampleRate: 1.0,
        replaysSessionSampleRate: 1.0,
        replaysOnErrorSampleRate: 1.0,
    });
}
